@media screen and (max-width: 1000px) {
  .container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    background-image: url("./Planet.png");
    background-repeat: no-repeat;
    background-position: bottom !important;
    padding-left: 0 !important;
  }
  .title {
    font-size: 45pt !important;
  }
  .subTitle {
    font-size: 22pt !important;
  }
}

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: black;
  background-image: url("./Planet.png");
  background-repeat: no-repeat;
  background-position: 200% 100% !important;
  background-size: contain;
  padding-left: 10rem;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 20px;
}

.button {
  background: linear-gradient(90deg, #da56d0, #6086ff);
  cursor: pointer;
  color: white;
  font-weight: bold;
  text-align: center;
  width: fit-content;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  border: none;
  font-size: 13pt;
}

.title {
  color: white;
  font-size: 100pt;
}
.subTitle {
  font-weight: 600;
  color: white;
  font-size: 30pt;
}
